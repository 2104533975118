import { useAuth0 } from "@auth0/auth0-react";

import { ValidateUserGroupsRolesGroups } from "../utils/ValidateUserGroupsRolesGroupsParams";

interface UseCanParams {
   roles?: string[];
   isNotInDepartment?: string[];
}

export function useCan({ roles }: UseCanParams) {
   const { isAuthenticated, user: auth0User } = useAuth0();

   const { "http://user/roles": userRoles, userPermissions } = auth0User;

   const user = {
      roles: userRoles,
      permissions: userPermissions,
   };

   if (!isAuthenticated) {
      return false;
   }

   const userHasValidPermissions = ValidateUserGroupsRolesGroups({
      user,
      roles,
   });

   if (!userHasValidPermissions) {
      return false;
   }

   return true;
}
