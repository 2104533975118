interface User {
   roles: string[];
}

type ValidateUserGroupsRolesGroupsParams = {
   user: User;
   roles?: string[];
};

export function ValidateUserGroupsRolesGroups({
   user,
   roles,
}: ValidateUserGroupsRolesGroupsParams) {
   if (roles?.length > 0) {
      const hasRoles = roles.some(role => {
         const userRoles = user.roles.map(userRole => userRole);

         return userRoles.includes(role);
      });

      if (!hasRoles) {
         return false;
      }
   }

   return true;
}
