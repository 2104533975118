import { Flex, Spinner } from "@chakra-ui/react";

function LoadingPage() {
   return (
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
         <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="beige.200"
            size="xl"
         />
      </Flex>
   );
}

export { LoadingPage };
