import Head from "next/head";

import { Image } from "@chakra-ui/react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FlexContainer } from "../components/Containers/FlexContainer";
import { LoadingPage } from "../components/Loading/LoadingPage";
import { Container } from "../components/Containers/Container";

function Home() {
   return (
      <>
         <Head>
            <title>Alta Vista Investimentos</title>
         </Head>
         <Container>
            <FlexContainer alignItems="center" justifyContent="center">
               <Image src="/av_wide_login_logo.png" />
            </FlexContainer>
         </Container>
      </>
   );
}

export default withAuthenticationRequired(Home, {
   onRedirecting: () => <LoadingPage />,
});

// export const getServerSideProps: GetServerSideProps = async () => {
//    return {
//       redirect: {
//          destination: "https://altavista.tradeinsights.com/dashboard",
//          permanent: false,
//       },
//    };
// };
