import { Box, Center, Icon, Tooltip } from "@chakra-ui/react";
import Link from "next/link";

interface SideBarLinkProps {
   src: string;
   icon: any;
   title: string;
   openInNewWindow?: boolean;
}

function SideBarLink({
   icon,
   src,
   title,
   openInNewWindow = false,
}: SideBarLinkProps) {
   return (
      <Link href={src} passHref target={openInNewWindow ? "_blank" : "_self"}>
         <Box
            display="inline-block"
            p="2"
            _hover={{
               backgroundColor: "#FFF",
               color: "beige.200",
               borderRadius: "50%",
            }}
         >
            <Tooltip
               hasArrow
               label={title}
               placement="right"
               bg="beige.300"
               fontWeight="bold"
               fontSize="md"
               gutter={20}
            >
               <Center>
                  <Icon as={icon} fontSize="25" />
               </Center>
            </Tooltip>
         </Box>
      </Link>
   );
}

export { SideBarLink };
