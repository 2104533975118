import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FlexContainerProps extends FlexProps {
   children?: ReactNode;
}

function FlexContainer({ children, ...rest }: FlexContainerProps) {
   return (
      <Flex
         h="100vh"
         overflow="auto"
         css={{
            "&::-webkit-scrollbar": {
               width: "10px",
               height: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
               background: "#7A6746",
               borderRadius: "16px",
            },
            "&::-webkit-scrollbar-track": {
               background: "#A18C6D",
            },
         }}
         p={["1em", "3em"]}
         flexDir="column"
         alignItems="center"
         {...rest}
      >
         {children}
      </Flex>
   );
}

export { FlexContainer };
