/* eslint-disable no-underscore-dangle */
import { useAuth0 } from "@auth0/auth0-react";
import {
   Flex,
   Box,
   Text,
   Icon,
   HStack,
   Avatar,
   MenuButton,
   Menu,
   MenuItem,
   MenuList,
   Image,
} from "@chakra-ui/react";
import { FaHubspot } from "react-icons/fa";
import { FiTrendingUp } from "react-icons/fi";
import { HiOutlineAcademicCap, HiOutlineCurrencyDollar } from "react-icons/hi";
import { RiLogoutBoxRLine, RiNotificationLine } from "react-icons/ri";

function Header() {
   const { logout, user, getIdTokenClaims } = useAuth0();

   const encondeEmail = encodeURIComponent(user.email);

   return (
      <Flex
         w="100%"
         as="header"
         mx="auto"
         py="3"
         px="6"
         align="center"
         borderBottomWidth="1px"
         borderBottomColor="beige.200"
      >
         <Box display="flex" alignItems="center" flexDir="row">
            <Image
               src="/logo-aguia2.png"
               width="100px"
               height="51px"
               alt="Alta Vista Investimentos"
            />
            <Text fontSize="3xl" fontWeight="bold" letterSpacing="tight" ml="6">
               Alta Vista Investimentos
            </Text>
         </Box>

         <Flex align="center" ml="auto">
            <HStack
               spacing="8"
               mx="8"
               pr="8"
               py="1"
               color="beige.200"
               borderRightWidth={1}
               borderColor="beige.300"
            >
               <Icon as={RiNotificationLine} fontSize="20" />
            </HStack>

            <Flex align="center">
               <Box mr="4" textAlign="right">
                  <Text>Olá,</Text>
                  <Text fontSize="medium">{user.name}</Text>
               </Box>

               <Menu>
                  <MenuButton>
                     <Avatar
                        size="md"
                        name={`${user.name} ${user.family_name}`}
                        src={user.picture}
                     />
                  </MenuButton>
                  <MenuList>
                     <MenuItem
                        icon={<HiOutlineCurrencyDollar size={20} />}
                        as="button"
                        onClick={async () => {
                           const token = await getIdTokenClaims();

                           window.open(
                              `${process.env.NEXT_PUBLIC_SPLITC_URL}${token.__raw}`,
                           );
                        }}
                     >
                        SplitC
                     </MenuItem>
                     <MenuItem
                        icon={<HiOutlineAcademicCap size={20} />}
                        as="button"
                        onClick={() => {
                           window.open(
                              "https://academia.altavistainvest.com.br/",
                           );
                        }}
                     >
                        Academia AV
                     </MenuItem>
                     <MenuItem
                        icon={<FiTrendingUp size={20} />}
                        as="button"
                        onClick={() => {
                           window.open(
                              process.env.NEXT_PUBLIC_TRADE_INSIGHTS_LINK,
                           );
                        }}
                     >
                        Tradeinsights
                     </MenuItem>
                     <MenuItem
                        icon={<FaHubspot size={20} />}
                        as="button"
                        onClick={() => {
                           window.open(
                              `https://app.hubspot.com/login/sso?loginPortalId=21698044&email=${encondeEmail}`,
                           );
                        }}
                     >
                        HubSpot
                     </MenuItem>
                     <MenuItem
                        icon={<RiLogoutBoxRLine size={20} />}
                        as="button"
                        onClick={() => logout({})}
                     >
                        Sair
                     </MenuItem>
                  </MenuList>
               </Menu>
            </Flex>
         </Flex>
      </Flex>
   );
}

export { Header };
